@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-size: 15px;
	background: #ffffff;
	color: #333333;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button, input, optgroup, select, textarea {
	font-family: 'Montserrat', sans-serif;
}

html, body, .App, .formMain {
	height: 100vh;
}

img {
	max-width: 100%;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

a:hover, a:focus, button:focus {
	text-decoration: none;
	color: inherit;
	outline: none;
	box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	color: #272727;
	font-family: 'Noto Serif', serif;
}

p {
	color: #272727;
}

.main-app-content, .hv-100 {
	height: calc(100vh - 42px);
	position: relative;
}

header {
	background: #272727;
	color: #fff;
	padding: 0.5rem 0 0.5rem 3rem;
}

header .dropdown {
	text-align: right;
	padding-right: 0;
	z-index: 1;
}

header .dropdown button, header .dropdown button:hover, header .dropdown button:focus {
	background: none;
	border: 0;
	padding: 0;
	box-shadow: none;
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
}

header .btn-primary:not(:disabled):not(.disabled).active, header .btn-primary:not(:disabled):not(.disabled):active, header .show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: transparent;
	border: 0;
}

header .dropdown svg {
	fill: #ffffff;
	width: 30px;
	height: auto;
	margin-right: 0.3rem;
}

header .dropdown-menu {
	padding: 0.4rem 0 0;
	margin: .125rem 0 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	min-width: 170px;
	right: 0;
	left: auto;
}

header .dropdown-menu:before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 7px;
	border-color: transparent transparent #f1f1f1 transparent;
	position: absolute;
	top: -8px;
	right: 0;
	left: 0;
	margin: auto;
}

header .dropdown-item {
	padding: .5rem 1rem;
	color: #272727;
	background-color: #f1f1f1;
	border: 0;
	text-align: center;
	font-size: 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

header .dropdown-item:last-child {
	border-bottom: 0
}

header .dropdown-item:hover, header .dropdown-item:focus {
	background-color: #666;
	;
	color: #fff;
}

header .dropdown-menu:hover:before {
	border-color: transparent transparent #f1f1f1 transparent;
}

header .headLink {
	position: relative;
	color: #ffffff;
	z-index: 1;
}

.hamburgerMen.btn-primary {
	background: none;
	padding: 0;
	border: 0;
	box-shadow: none;
	line-height: 1;
	margin: 0.75rem;
	text-align: left;
}

.hamburgerMen.btn-primary:hover, .hamburgerMen.btn-primary:focus {
	background: none;
	box-shadow: none;
}

.hamburgerMen svg {
	fill: #fff;
	width: 22px;
	height: auto;
}

.siteLogo {
	color: #fff;
	text-align: center;
	padding: 1rem 0;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.2;
	background: #212121;
}

.siteLogo span {
	display: block;
	font-size: 2rem;
	font-weight: 700;
}

.homeBg {
	position: relative;
}

.imageBlock {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 35%;
	height: 100%;
}

.imageBlock img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.buttons-group {
	position: relative;
}

.btn-primary.disabled, .btn-primary:disabled {
	color: #fff;
	background-color: #7b7b7b;
	border-color: #7b7b7b;
}

.btn-primary, .btn-outline-primary {
	padding: 1rem 2.5rem;
	min-width: 130px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
}

.btn-primary {
	background: #272727;
	color: #fff;
	border: 1px solid #272727;
}

.btn-outline-primary {
	background: none;
	color: #272727;
	border: 1px solid #272727;
}

.btn-primary:hover, .btn-outline-primary:hover {
	color: #fff;
	background-color: #000000;
	border-color: #000000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #272727;
	border-color: #272727;
}

.btn-outline-primary.focus, .btn-outline-primary:focus, .btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #272727;
	border-color: #272727;
}

.btn-secondary.focus, .btn-secondary:focus {
	box-shadow: none;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #8c8c8c;
	outline: 0;
	box-shadow: none;
}

.fileDrag {
	cursor: pointer;
}

.fileDrag * {
	outline: none;
}

.fileDrag svg {
	width: 100px;
	height: auto;
	margin-bottom: 1rem;
}

.stepForm {
	padding-top: 5rem;
	color: #272727;
	padding-bottom: 4rem;
}

.stepsContent {
	border: 2px dashed #272727;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6rem 1rem;
	min-height: 200px;
	text-align: center;
}

.navSteps {
	display: flex;
	margin: 0 0 3rem;
	padding: 0;
	list-style: none;
	justify-content: center;
	align-items: center;
	color: #272727;
	text-transform: uppercase;
	font-size: 13px;
}

.navSteps li {
	font-weight: 700;
	margin: 0 2rem;
	position: relative;
	min-width: 65px;
	text-align: center;
}

.navSteps li span {
	display: block;
	margin: auto auto 1.5rem;
	width: 7px;
	height: 7px;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
	background: #272727;
	position: relative;
}

.navSteps li.active span:after {
	background: #eeeeee;
	position: absolute;
	content: '';
	width: 40px;
	height: 40px;
	top: 50%;
	left: 50%;
	border-radius: 100px;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	z-index: -1;
}

.navSteps li:before {
	background: #eeeeee;
	position: absolute;
	content: '';
	width: 200%;
	height: 2px;
	top: 3px;
	left: 50%;
	z-index: -2;
}

.navSteps li:last-child:before {
	display: none;
}

.uploadSteps {
	color: #272727;
}

.uploadSteps li span {
	background: #272727;
}

.uploadSteps li.active span:after {
	background: #eeeeee;
}

.uploadSteps li:before {
	background: #eeeeee;
	width: 220%;
}

.stepForm .btn-primary, .stepForm .btn-outline-primary {
	padding: 0.7rem 2.5rem;
	min-width: 130px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	border-color: #272727;
}

.stepForm .btn-primary {
	background: #272727;
	color: #fff;
}

.stepForm .btn-outline-primary {
	background: none;
	color: #272727;
	border: 1px solid #272727;
}

.stepForm .btn-outline-primary.focus, .stepForm .btn-outline-primary:focus, .stepForm .btn-primary.focus, .stepForm .btn-primary:focus {
	box-shadow: none
}

.switchDiv input[type=checkbox] {
	height: 0;
	width: 0;
	visibility: hidden;
}

.switchDiv label {
	cursor: pointer;
	text-indent: -9999px;
	width: 80px;
	height: 40px;
	background: #27272747;
	display: block;
	border-radius: 5px;
	position: relative;
	margin: 0.3rem auto auto;
}

.switchDiv label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 3px;
	transition: 0.3s;
}

.switchDiv input:checked+label {
	background: #272727;
}

.switchDiv input:checked+label:after {
	left: auto;
	right: 5px;
}

.form-group {
	color: #272727;
}

.form-group label {
	font-weight: 600;
}

.scriptForm .form-control {
	color: #272727;
	background-color: #eaeaea12;
	border: 1px solid #272727;
	border-radius: 0.2rem;
	-webkit-border-radius: 0.2rem;
	-moz-border-radius: 0.2rem;
	-ms-border-radius: 0.2rem;
	-o-border-radius: 0.2rem;
	font-size: 14px;
}

.scriptForm .form-control::-webkit-input-placeholder {
	color: #272727;
	background-color: #eaeaea12;
}

.form-group ul {
	display: flex;
	flex-flow: row wrap;
}

.form-group ul li {
	border: 2px solid #2e99fb50;
	background: #2e99fb0f;
	color: #2e99fb;
	border-radius: 0.25rem;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	-ms-border-radius: 0.25rem;
	-o-border-radius: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.2rem 0.5rem;
	cursor: pointer;
}

.slick-slide {
	padding: 0 2rem;
}

.slideCol {
	background: #27272712;
	padding: 2rem;
	border: 2px solid #27272740;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.slick-slider {
	padding-bottom: 4rem;
}

.slick-dots {
	bottom: 0;
}

.slick-dots li {
	margin: 0 0.5rem;
}

.slick-dots li button {
	font-size: 15px;
	line-height: 20px;
	display: block;
	width: 28px;
	height: 28px;
	padding: 0px;
	cursor: pointer;
	color: #272727;
	border: 2px solid #272727;
	background: none;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

.slick-dots .slick-active button {
	background: #272727;
	color: #fff;
	border: 2px solid #272727;
}

.slick-dots li button:before {
	display: none;
}

.tag {
	border: 2px solid #27272750;
	background: #2727270f;
	color: #272727;
	border-radius: 0.25rem;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	-ms-border-radius: 0.25rem;
	-o-border-radius: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.2rem 0.5rem;
}

.tag-btn {
	padding: 0.2rem 0.5rem;
	color: #272727;
	background-color: #2727270f;
	border: 2px solid #27272750;
	;
	border-radius: 0.25rem;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	-ms-border-radius: 0.25rem;
	-o-border-radius: 0.25rem;
}

.tag-btn:hover {
	background-color: #ffffff;
	color: #000;
	border: 2px solid #27272750;
}

.customNav, .customNav ul {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.customNav ul li, .customNav .nextArrow, .customNav .prevArrow {
	margin: 0 1rem;
}

.customNav ul li button {
	font-size: 15px;
	line-height: 20px;
	display: block;
	width: 28px;
	height: 28px;
	padding: 0px;
	cursor: pointer;
	color: #272727;
	border: 2px solid #272727;
	background: none;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

.customNav ul .active button, .customNav ul li button:hover, .customNav ul li button:focus {
	background: #272727;
	color: #fff;
	border: 2px solid #272727;
	outline: none;
	box-shadow: none;
}

.customNav .prevArrow, .customNav .nextArrow {
	background: none;
	color: #272727;
	padding: 0;
	border: 0;
	box-shadow: none;
	position: relative;
	min-width: 0;
	height: 28px;
	width: 28px;
	text-align: center;
}

.customNav .prevArrow:hover, .customNav .nextArrow:hover, .customNav .prevArrow:focus, .customNav .nextArrow:focus {
	background: none;
	border: 0;
	box-shadow: none;
}

.customNav .prevArrow:before, .customNav .nextArrow:before {
	font-size: 20px;
	opacity: .75;
	color: #272727;
	font-family: 'FontAwesome';
	position: absolute;
	width: auto;
	height: auto;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0;
	line-height: 32px;
}

.customNav .prevArrow:hover:before, .customNav .nextArrow:hover:before {
	opacity: 1;
}

.customNav .prevArrow:before {
	content: "\f053";
}

.customNav .nextArrow:before {
	content: "\f054";
}

.main-sidebar {
	height: calc(100% - 98px);
	margin: 0;
	min-width: 200px;
	padding-top: 1rem;
}

.main-sidebar li a {
	padding: 0.5rem 1rem 0.5rem 2rem;
	display: block;
	text-decoration: none;
	outline: none;
	color: #ffffff;
	position: relative;
}

.main-sidebar li a:hover {
	background: rgba(0, 0, 0, 0.2);
}

.main-sidebar li a::before {
	position: absolute;
	content: '\203A';
	left: 1rem;
	font-size: 23px;
	top: 0;
	bottom: 0;
	margin: auto;
}

.downloadModal .modal-header {
	margin-bottom: 1rem;
	border-bottom: 1px solid #ddd;
	padding: 8px 15px;
	font-size: 1.2rem;
	position: relative;
}

.downloadModal .modal-header .modal-title {
	font-size: 1.2rem;
}

.downloadModal .modal-header .close {
	cursor: pointer;
	position: absolute;
	right: 8px;
	display: inline-block;
	width: 22px;
	height: 22px;
	text-align: center;
	line-height: 22px;
	background: #999999;
	color: #fff;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
	padding: 0;
	margin: 0;
	opacity: 1;
	font-size: 100%;
	font-weight: 400;
	top: 1rem;
}

.downloadModal .modal-body {
	padding: 0;
}

.downloadModal .dropdown {
	padding: 0 15px 15px;
}

.downloadModal .dropdown .btn-secondary {
	background-color: transparent;
	border: 1px solid #495057;
	color: #272727;
	font-size: 15px;
	border-radius: 0.2rem;
	padding: 0.6rem 1rem;
}

.downloadModal .dropdown-menu {
	min-width: 200px;
	padding: 0;
}

.downloadModal .dropdown svg {
	fill: #272727;
	height: auto;
	width: 25px;
	margin: auto 0.5rem auto auto;
}

.downloadModal .dropdown-item {
	padding: .5rem 1rem;
	line-height: 35px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	text-transform: capitalize;
}

.downloadModal .dropdown-item:last-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.downloadCol {
	height: calc(100% - 40px);
	border: 1px solid #bfe0fe;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.downloadCol>p {
	border-radius: 10px 10px 0 0;
	-webkit-border-radius: 10px 10px 0 0;
	-moz-border-radius: 10px 10px 0 0;
	-ms-border-radius: 10px 10px 0 0;
	-o-border-radius: 10px 10px 0 0;
	padding: 0.85rem 0.5rem;
	font-size: 15px;
	background: #c7e7ff
}

.scriptCol .card-title {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 17px;
}

.scriptCol .card-subtitle {
	font-size: 13px;
	margin-bottom: 1rem;
}

.scriptCol .card-subtitle, .scriptCol .card-text {
	font-style: italic;
}

.scriptCol .card-text {
	margin-bottom: 1.5rem;
}

.scriptCol a.card-link {
	font-weight: 600;
	font-size: 13px;
	color: #1e6e94;
}

.scriptCol {
	height: 100%;
}

.downloadFile {
	background: #f3f3f3;
	padding: 2rem;
	border: 1px solid #eee;
	height: 100%;
}

.downloadFile p {
	font-style: italic;
}

.downloadFile p strong {
	min-width: 140px;
	display: inline-block;
	font-style: normal;
}

.downloadFile .btn-outline-primary {
	padding: 0.7rem 1rem;
	text-transform: capitalize;
	font-weight: 400;
	min-width: 0;
	border-color: #00a4b9;
}

.downloadFile .btn-outline-primary svg {
	width: 25px;
	height: auto;
	margin-right: 0.5rem;
}

.pageBanner {
	padding: 5rem 0;
	color: #ffffff;
	position: relative;
}

.pageBanner img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	object-fit: cover;
	height: 100%;
}

.pageBanner h1 {
	color: #fff;
	font-family: 'Montserrat', sans-serif;
	font-size: 2rem;
	position: relative;
}

.pageBanner {
	position: relative;
}

.pageBanner .btn-outline-primary {
	color: #fff;
	border-color: #ffffff;
}

.pageBanner .btn-outline-primary:hover {
	background-color: #ffffff;
	color: #272727;
}

.trendingBlock h2 {
	font-size: 1.5rem;
	font-family: 'Montserrat', sans-serif;
}

.cta {
	background: #ddd;
	padding: 5rem 0
}

.cta h3 {
	font-size: 1rem;
	font-family: 'Montserrat', sans-serif;
}

.cta p {
	margin-bottom: 2rem;
}

.cta .btn-outline-primary {
	padding: 0.5rem 2.5rem;
}

footer {
	background: #222222;
	color: #fff;
	padding: 1.2rem 0;
}

footer p {
	margin: 0;
	color: #ffffff;
	font-size: 13px;
}

.profilePage {
	padding: 6rem 0 2rem;
}

.profilePage form {
	background: #fff;
	box-shadow: 0 0 50px -20px rgba(0, 0, 0, .2);
	padding: 1.5rem;
	border-radius: .3rem;
	-webkit-border-radius: .3rem;
	-moz-border-radius: .3rem;
	-ms-border-radius: .3rem;
	-o-border-radius: .3rem
}

.profilePage form input {
	font-size: small;
}

.profilePage .fileUpload {
	text-align: center;
	display: block;
	cursor: pointer;
	border: 2px dashed rgba(0, 0, 0, 0.2);
	padding: 2rem;
	margin: 0;
}

.profilePage .fileUpload svg {
	width: 220px;
	height: auto;
	margin: auto auto 1rem;
	display: block;
}

.pagination {
	border-radius: 0;
	justify-content: center;
	padding: 0;
	margin-top: 1rem
}

.pagination>li {
	display: inline-block;
	padding-left: 0
}

.pagination>li {
	list-style: none;
	border: 0;
	margin: 0 .1rem
}

.pagination>li>a, .pagination>li>span {
	position: relative;
	float: left;
	padding: 4px 10px;
	line-height: 1.42857143;
	text-decoration: none;
	background: #080015;
	color: #fff !important;
	border: 0;
	margin-left: 0
}

.pagination>li.active>a {
	color: #fff;
	background: #2e99fb
}

.pagination>li>a:hover {
	background-color: #2e99fb;
	color: #fff
}

.videoScript .scriptCol .imageWrap {
	position: relative;
	background: #000;
}

.scriptCol .bitIcon {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	z-index: 111;
	background: #30a4b9;
	width: 42px;
	height: 42px;
	line-height: 42px;
	text-align: center;
	color: #ffffff;
	font-size: 20px;
}

.scriptCol .playIcon {
	position: absolute;
	cursor: pointer;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	background: #00000060;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #666;
	font-size: 20px;
}

.scriptCol .playIcon i {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: #666;
	font-size: 20px;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

.scriptCol:hover .playIcon {
	background: #00000090;
}

.videoScript .scriptCol .card-text {
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.videoScript .scriptCol .card-body span {
	cursor: pointer;
}

.downloadModal video {
	background: #e8e7e7;
	padding: 8px;
	margin-bottom: 0.7rem;
}

.videoModal .modal-dialog {
	max-width: 750px;
}

.videoModal .modal-header {
	border-bottom: 0;
}

.backBtn {
	min-width: 0;
	padding: 0;
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.backBtn i {
	margin: 0;
}

.scriptCol .checkIcon {
	position: absolute;
	left: 12px;
	top: 12px;
	z-index: 111;
}

.scriptCol .input-group-text {
	padding: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.scriptCol .input-group-text input[type=checkbox] {
	height: 20px;
	width: 20px;
}

.bucketScript .scriptCol {
	padding: 0.2rem;
}

button i {
	vertical-align: middle;
	margin-right: 0.5rem;
}

span.loginLogoText {
	display: inline-block;
	margin-left: 10px;
	margin-top: 14px;
}

/*-==-====--=-=-=-=-=
Media CSS
-=-=-=-=-=-==-=-=-=-=*/
@media (min-width:1400px) {
	.cta {
		height: calc(100vh - 385px);
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.btn-primary, .btn-outline-primary {
		padding: 1rem 1.5rem;
	}

	.cta {
		height: calc(100vh - 290px);
	}
}

@media (min-width:768px) and (max-width:991px) {
	.btn-primary, .btn-outline-primary {
		padding: 1rem 1.5rem;
	}

	.profilePage {
		padding: 4rem 0 2rem;
	}

	.cta {
		height: calc(100vh - 290px);
	}
}

@media (max-width:767px) {
	header {
		padding: 0.5rem 0 0.5rem 2rem;
	}

	.imageBlock {
		display: none;
	}

	.homeBg {
		padding: 2rem 0;
	}

	.downloadFile, .scriptCol {
		height: auto;
	}

	.cta {
		height: auto
	}
}

@media (max-width:575px) {
	.btn-primary, .btn-outline-primary {
		padding: 1rem 1rem;
		min-width: 0;
	}

	.downloadCol {
		height: auto;
	}

	.navSteps li {
		margin: 0 1rem;
	}

	.navSteps {
		margin: 0 0 2rem;
	}

	.navSteps li:before {
		width: 150%;
	}

	.uploadSteps li:before {
		background: #eeeeee;
		width: 170%;
	}

	.profilePage {
		padding: 4rem 0 2rem;
	}

	.profilePage .fileUpload {
		margin-bottom: 1.2rem;
	}

	.cta {
		height: calc(100vh - 290px);
	}
}

.bitbucket {
	display: inline-block;
	position: relative;
	top: 5px;
}

.bitbucket span {
	display: block;
	position: absolute;
	bottom: auto;
	right: 0;
	background: #30a4b9;
	color: #ffffff;
	border-radius: 100px;
	width: 17px;
	height: 17px;
	text-align: center;
	font-weight: bold;
	top: -8px;
	font-size: 11px;
}

.bitbucket .fab.fa-bitbucket {
	font-size: 28px;
	margin-right: 10px;
	cursor: pointer;
}

.scriptCol .right-icon {
	position: absolute;
	left: 15px;
	top: 15px;
	cursor: pointer;
	z-index: 111;
	background: none;
	text-align: center;
	color: #ffffff;
	font-size: 20px;
}

.right-icon i {
	color: #fff;
	border-radius: 100px;
	font-size: 15px;
	background: #30a4b9;
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.shuffle {
	text-align: right;
	padding-right: 50px;
	padding-top: 10px;
}

.heart-selected {
	color: red;
}

.clear-bucket {
	height: 3.65rem;
}

.redirect-url {
    text-align: center;
    padding-top: 100px;
}
